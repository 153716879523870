import React from 'react'
import Loader from 'react-loader-spinner'

const Loading = () => (
  <div className='center mt4'>
    <Loader
      type="Oval"
      color="#957E51"
      height="100"
    />
  </div>
)

export default Loading
